<template>
    <div class="user-management">
<!--        <b-row> -->
<!--            <b-col sm="6">-->
<!--                <InviteUser />-->
<!--            </b-col>-->

<!--        </b-row>-->
        <div class="actions">
            <b-button variant="outline-info" @click="inviteUser"><AccountPlus /> Invite User</b-button>
        </div>
        <b-row class="mt-4">
            <b-col cols="12">
                <UserTable ref="userTable" />
            </b-col>
        </b-row>
        <InviteUserModal ref="inviteUserModal" @user-added="refresh" />
    </div>
</template>

<script>
    import AccountPlus from 'vue-material-design-icons/AccountPlusOutline';
    import InviteUserModal from "../../components/admin/user-management/InviteUserModal";
    import UserTable from "../../components/admin/user-management/UserTable";
    export default {
        name: "UserManagement",
        components: {UserTable, InviteUserModal, AccountPlus},
        methods: {
            inviteUser() {
                this.$refs.inviteUserModal.show();
            },
            refresh() {
                this.$ref.userTable.refresh()
            }
        }
    }
</script>

<style scoped>

</style>