let user = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    secondaryEmailAddress: '',
    mentors: [],
    jobRoles: [],
    facility: null,
    startDate: null,
    type: 'User',
    accessRoles: [],
    accessFacilities: [],
    accessUnits: []
};

export default user;