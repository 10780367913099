<template>
    <div class="invite-user">
        <b-modal class="invite-user-modal" title="Invite User" ref="modal" size="lg" okTitle="Send Invite"
                 okVariant="primary" :ok-disabled="loading" header-bg-variant="primary" header-text-variant="light"
                 @ok="invite">
            <b-overlay :show="loading">
                <UserInformation :user="user" />
                <small class="mt-2">An email with a signup link will be sent to the email address above. Once accepted, they'll be granted the relevant access to this system based on the User Type selected. </small>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../../providers';
    import UserInformation from "./UserInformation";
    import DEFAULT_USER from './defaultUser';

    export default {
        name: "InviteUserModal",
        components: {UserInformation},
        data() {
            return {
                user: {
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    facility: null,
                    jobRoles: [],
                    type: 'User',
                },
                loading: false,
            }
        },
        methods: {
            reset() {
                this.user = {...DEFAULT_USER}
            },
            invite(e) {
                e.preventDefault();
                this.loading = true;
                Providers.admin.userManagement.createUser(this.user).then(() => {
                    this.$success('Invite Sent', 'The invite email has been sent successfully');
                    this.reset();
                    this.$emit('user-added');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to invite user', resp);
                }).finally(() => {
                    this.loading = false;
                });
                return false;
            },
            show() {
                this.reset();
                this.$refs.modal.show();
            }
        },
        created() {
            this.reset();
        }
    }
</script>

<style scoped>

</style>