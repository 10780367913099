<template>
    <div class="user-table">
        <b-overlay :show="loading">
            <b-row>
                <b-col cols="12" md="4" offset-md="8" lg="3" offset-lg="9" offset="0">
                    <b-form-input type="text" placeholder="Search..." v-model="filter" trim debounce="250" />
                </b-col>
            </b-row>
            <b-table ref="table" class="mt-2" :fields="fields" :items="userProvider" :per-page="perPage" :busy.sync="isBusy" :current-page="currentPage" :filter="filter">
                <template v-slot:cell(role)="data">
                    <b-badge :variant="userTypeIndicator(data.item.role)" pill>{{uppercase(data.item.role)}}</b-badge>
                </template>
                <template v-slot:cell(enabled)="data">
                    <b-badge variant="success" v-if="data.item.enabled">Enabled</b-badge>
                    <b-badge variant="danger" v-else>Disabled</b-badge>
                </template>
                <template v-slot:cell(actions)="data">
                    <div class="actions">
                        <b-button size="sm" variant="outline-secondary" @click="edit(data.item.userId)">Edit</b-button>
                        <b-button size="sm" variant="outline-warning" v-if="data.item.enabled" @click="disable(data.item.userId)">Disable</b-button>
                        <b-button size="sm" variant="outline-success" v-if="!data.item.enabled" @click="enable(data.item.userId)">Enable</b-button>
                        <b-button size="sm" variant="outline-danger" disabled>Delete</b-button>
                        <b-button size="sm" variant="outline-secondary" @click="reset(data.item.userId)">Reset Password</b-button>
                    </div>
                </template>
            </b-table>
            <b-pagination :disabled="isBusy" align="center" :hideGotoEndButtons="true" prevText="Previous" nextText="Next" size="sm" :limit="10" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
        </b-overlay>
        <UpdateUserModal ref="updateUserModal" @user-updated="refresh" />
    </div>
</template>

<script>
    import Providers from '../../../providers';
    import FormatterUtils from 'cpdcloud-dashboard/src/utilities/FormatterUtils';
    import UpdateUserModal from "./UpdateUserModal";

    export default {
        name: "UserTable",
        components: {UpdateUserModal},
        data() {
            return {
                isBusy: false,
                loading: false,
                totalRows: 0,
                perPage: 10,
                currentPage: 1,
                filter: '',
                fields: [
                    {
                        key: 'firstName',
                        label: 'First Name',
                    },
                    {
                        key: 'lastName',
                        label: 'Last Name'
                    },
                    {
                        key: 'role',
                        label: 'Role'
                    },
                    {
                        key: 'enabled',
                        label: 'Status'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        },
        methods: {
            userProvider(ctx, callback) {
                this.isBusy = true;
                Providers.admin.userManagement.users(ctx.perPage, ctx.currentPage, ctx.filter).then(response => {
                    callback(response.body.items);
                    this.totalRows = response.body.totalItems
                }).catch((resp) => {
                    this.$httpError('Failed to load users', resp);
                }).finally(() => {
                    this.isBusy = false;
                });
                return null
            },
            refresh() {
                this.$refs.table.refresh();
            },
            uppercase(type) {
                return FormatterUtils.capitalize(type);
            },
            edit(userId) {
                this.$refs.updateUserModal.show(userId);
            },
            enable(userId) {
                this.loading = true;
                Providers.admin.userManagement.enableUser(userId).then(() => {
                    this.$success('User Enabled', 'The user has been enabled successfully. They can now log into the system.');
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to enable user', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            disable(userId) {
                this.loading = true;
                Providers.admin.userManagement.disableUser(userId).then(() => {
                    this.$success('User Disabled', 'The user has been disabled successfully.');
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to disable user', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset(userId) {
                this.loading = true;
                Providers.admin.userManagement.resetPassword(userId).then(() => {
                    this.$success('Password Reset Instructions Sent', 'The instructions to reset password have been sent to the user');
                }).catch((resp) => {
                    this.$httpError('Failed to reset user password', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            userTypeIndicator(type) {
                switch(type) {
                    case 'Admin':
                        return 'primary';
                    case 'Manager':
                        return 'info';
                    case 'Mentor':
                        return 'warning';
                    default:
                        return 'light'
                }
            }

        }
    }
</script>

<style scoped>
    .actions > * {
        margin: 0 4px;
    }
</style>
