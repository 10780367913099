<template>
    <div class="user-information">
        <b-row>
            <b-col cols="6">
                <b-form-group label="First Name *">
                    <b-form-input v-model="user.firstName" type="text" placeholder="" autocomplete="off"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Last Name *">
                    <b-form-input v-model="user.lastName" type="text" placeholder="" autocomplete="off"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-form-group label="Email Address *">
            <b-form-input v-model="user.emailAddress" type="email" placeholder="" autocomplete="off"></b-form-input>
            <small>This is the email address which the user will login with.</small>
        </b-form-group>
        <b-form-group label="Secondary Email Address">
            <b-form-input v-model="user.secondaryEmailAddress" type="email" placeholder="" autocomplete="off"></b-form-input>
            <small>(Optional) This address will be CC'd into any communication with the user.</small>
        </b-form-group>
        <b-form-group label="Job Roles *">
            <v-select v-model="user.jobRoles" multiple :options="roles" label="name" :reduce="opt => opt.id">

            </v-select>
        </b-form-group>
        <b-form-group label="Facility *">
            <b-form-select :options="facilities" v-model="user.facility">
                <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Please select --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group label="Start Date *">
            <b-form-datepicker v-model="user.startDate">

            </b-form-datepicker>
            <small>Choose the start date for this user. The first reporting period will start from this date, and subsequent reporting periods are determined by annual reviews.</small>
        </b-form-group>
        <b-form-group label="User Type *">
            <b-form-select :options="userTypes" v-model="user.type"></b-form-select>
        </b-form-group>
        <div class="manager-permissions" v-if="user.type === 'Manager'">
            <b-form-group label="Access - Roles">
                <v-select v-model="user.accessRoles" multiple :options="roles" label="name" :reduce="opt => opt.id">

                </v-select>
                <small>Provides access to roles across the system independent of Unit/Facility</small>
            </b-form-group>
            <b-form-group label="Access - Units">
                <v-select v-model="user.accessUnits" multiple :options="units" label="name" :reduce="opt => opt.id">

                </v-select>
                <small>Provides access to all facilities within each unit</small>
            </b-form-group>
            <b-form-group label="Access - Facilities">
                <v-select v-model="user.accessFacilities" multiple :options="flatFacilities" label="text" :reduce="opt => opt.value">

                </v-select>
                <small>Provides access to staff within specific facilities</small>
            </b-form-group>
        </div>
        <b-form-group label="Mentors">
            <v-select v-model="user.mentors" multiple :options="mentors" label="name" :reduce="opt => opt.id">

            </v-select>
            <small>(Optional) Assign the mentors that this user can send their entries for approval to.</small>
        </b-form-group>
        <small style="font-style: italic">* required field</small>
    </div>
</template>

<script>
    import DEFAULT_USER from './defaultUser';
    import vSelect from 'vue-select';
    import Providers from "../../../providers";

    export default {
        name: "UserInformation",
        components: {vSelect},
        props: {
            user: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_USER})
            }
        },
        data() {
            return {
                loading: false,
                facilities: [],
                flatFacilities: [],
                units: [],
                roles: [],
                mentors: [],
                userTypes: ['User', 'Mentor', 'Manager', 'Admin', 'Superadmin'],
            }
        },
        methods: {
            loadRoles() {
                this.loading = true;
                Providers.admin.configuration.roles().then((resp) => {
                    this.roles = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load roles', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            loadUnits() {
                this.loading = true;
                Providers.admin.configuration.units().then((resp) => {
                    this.units = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load units', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            loadFacilities() {
                this.loading = true;
                Providers.admin.configuration.allFacilities().then((resp) => {
                    this.flatFacilities = [];
                    for (let unit of resp.body) {
                        for (let facility of unit.facilities) {
                            this.flatFacilities.push({
                                text: unit.name + ' - ' + facility.name,
                                value: facility.id
                            });
                        }
                    }
                    this.facilities = resp.body.map(unit => {
                        return {
                            label: 'Unit: ' + unit.name,
                            options: unit.facilities.map(fac => {
                                return {
                                    text: fac.name,
                                    value: fac.id
                                }
                            })
                        };
                    });
                }).catch((resp) => {
                    this.$httpError('Failed to load facilities', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            loadMentors() {
                this.loading = true;
                Providers.admin.userManagement.availableMentors().then((resp) => {
                    this.mentors = resp.body.map(mentor => {
                        return {
                            name: `${mentor.firstName} ${mentor.lastName}`,
                            id: mentor.id
                        }
                    });
                }).catch((resp) => {
                    this.$httpError('Failed to load mentors', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadRoles();
            this.loadFacilities();
            this.loadMentors();
            this.loadUnits();
        }
    }
</script>

<style scoped>

</style>