<template>
    <div class="update-user">
        <b-modal class="update-user-modal" title="Update User" ref="modal" size="lg" okTitle="Save Changes"
                 okVariant="primary" :ok-disabled="loading" header-bg-variant="light" header-text-variant="dark"
                 @ok="update">
            <b-overlay :show="loading">
                <UserInformation v-if="user" :user="user" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../../providers/index';
    import UserInformation from "./UserInformation";

    export default {
        name: "UpdateUserModal",
        components: {UserInformation},
        data() {
            return {
                loading: false,
                user: null,
                userId: null
            }
        },
        methods: {
            show(userId) {
                this.$refs.modal.show();
                this.loading = true;
                Providers.admin.userManagement.user(userId).then((resp) => {
                    this.user = resp.body;
                    this.userId = userId;
                }).catch((resp) => {
                    this.$httpError('Failed to load user', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            update(e) {
                  e.preventDefault();
                  this.loading = true;
                  Providers.admin.userManagement.updateUser(this.userId, this.user).then(() => {
                      this.$success('User Updated', 'The user has been updated successfully');
                      this.$emit('user-updated');
                      this.close();
                  }).catch((resp) => {
                      this.$httpError('Failed to update user', resp);
                  }).finally(() => {
                      this.loading = false;
                  })
            },
            close() {
                this.$refs.modal.hide();
            },
            reset() {
                this.userId = null;
                this.user = null;
            }
        }
    }
</script>

<style scoped>

</style>